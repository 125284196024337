<div class="modal">
  <div class="modal-dialog" role="dialog" aria-hidden="true">
    <div class="modal-content">
      <div class="modal-header">
        <h3> {{ 'sharedSales.ajcaisse' | translate }} </h3>
        <svg xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;" (click)="CloseAction()" width="20" height="20"
          fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
          <path
            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
        </svg>
      </div>
      <div class="modal-body">
        <form clrForm>
          <clr-input-container>
            <label> {{ 'Caisses.NumC' | translate }}*</label>
            <input clrInput type="text" name="account" [(ngModel)]="cratesObject.numero" />
          </clr-input-container>
          <clr-input-container>
            <label>{{ 'sales.nomCaisse' | translate }}*</label>
            <input clrInput type="text" name="name" [(ngModel)]="cratesObject.name" />
          </clr-input-container>
          <clr-combobox-container>
            <label>{{ 'sales.ptVente' | translate }} *</label>
            <clr-combobox name="pointid" required [clrLoading]="loading" (clrInputChange)="onSearchPoint($event)"
              placeholder="Point de vente">
              <clr-options *ngIf="points">
                <clr-option *ngFor="let item of points" clrValue="{{item.name}}" (click)="itemChange(item)">
                  {{item.name}}
                </clr-option>
              </clr-options>
              <clr-control-error>{{ 'reg.ob' | translate }}</clr-control-error>
            </clr-combobox>
          </clr-combobox-container>
          <clr-control-helper>
            <button id="btn_caisse" class="btn btn-link btn-sm" (click)="showAddPoint = true">
              {{ 'Paramtrage.ajptv' | translate }}
            </button>
          </clr-control-helper>
          <br>
        </form>
      </div>
      <div class="modal-footer">
        <button class="btn btn-outline" (click)="CloseAction()"> {{ 'commun.nn' | translate }} </button>
        <button class="btn btn-success" [disabled]="!cratesObject.numero || !cratesObject.name" (click)="submit()"> {{
          'commun.jt'
          | translate }}</button>
      </div>
    </div>
  </div>
</div>
<div class="modal-backdrop" aria-hidden="true"></div>
<app-add-sales-point-modal *ngIf="showAddPoint" (saved)="getSalesPoint($event)" (closed)="CloseAction()"
  [type]="responsable"></app-add-sales-point-modal>