<div class="wrapper">
  <div class="demo-wrapper">
    <span>{{ 'demo.vp' | translate }}</span>
    <button class="demo-button"  (click)="showVideoDemo()" id="demo-button">
      <i class="fab fa-youtube fa-spin" aria-hidden="true" id="fa-youtube"></i>
      <span class="demo-span" id="demo-span">Demo</span>
    </button>
  </div>
<h3 dir="rtl">{{ 'article.Prod.MPro' | translate }}</h3>
<button *ngIf="isAdmin || (userAutorisation == null && userRole != 'writer' && userRole != 'carrier') || userAutorisation.addArticle == true" type="button" class="btn  btn-success btn-sm" style="font-size: x-small;  
text-align:center" routerLink="/catalog/product/add">
  <svg id="btn_plus" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-plus">
    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
  </svg>{{ 'commun.crn' | translate }} 
</button>
<a  routerLink="/product/printList" *ngIf="isAdmin || (userAutorisation == null && userRole != 'carrier') || userAutorisation.printArticle == true"  target="_blank">
  <button type="button" class="btn  btn-success btn-sm" style="font-size: x-small;  
  text-align:center">
    {{ 'commun.impliste' | translate }}
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-printer"
      viewBox="0 -4 16 16">
      <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
      <path
        d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
    </svg>
  </button>
</a>
<br>
<div class="filter">
  <input type="text" id="search_text" (input)="search_product($event)" placeholder="{{ 'sales.nomProd' | translate }}" [(ngModel)]="search_text">
</div>
<div>
  <table class="table table-hover">
    <thead>
      <tr>
        <th style="text-align: center;">{{ 'commun.id' | translate }}</th>
        <th style="text-align: center;">{{ 'article.Prod.Réference' | translate }}</th>
        <th style="text-align: center;">{{ 'article.Prod.Pro' | translate }}</th>
        <th style="text-align: center;">{{ 'article.Prod.Prixbuy' | translate }}</th>
        <th style="text-align: center;">{{ 'article.Prod.Prix' | translate }}</th>
        <th style="text-align: center;">{{ 'article.Prod.qnt' | translate }}</th>
        <th style="text-align: center;"></th>
        <th style="text-align: center;"></th>
        <th style="text-align: center;"></th>
        <th style="text-align: center;"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of showProduct">
        <td scope="row">{{item?.product?.real_id}}</td>
        <td>
          <span>{{item?.product?.reference}}</span>
          <span *ngIf="!item?.product?.reference" class="label label-danger"> {{ 'commun.n' | translate }} !</span>
        </td>
        <td>
          <span class="label label-info">{{item?.product?.name}}
            &nbsp;&nbsp;&nbsp;
          </span>
          <!-- <span *ngIf="!item.product.forBuying" class="label label-info">{{ 'commun.v' | translate }}</span>
          <span *ngIf="item.product.forBuying" class="label label-success">{{ 'commun.p' | translate }}</span> -->
          <span *ngIf="!item.product.name" class="label label-danger"> {{ 'commun.nd' | translate }} </span>
        </td>
        <td>
          <span *ngIf="item.product.priceBuy">{{item?.product?.priceBuy.toFixed(2)}} {{devise}}</span>
          <span *ngIf="!item.product.priceBuy" class="label label-danger"> {{ 'commun.nd' | translate }}</span>
        </td>
        <td>
          <span *ngIf="item.product.price">{{item?.product?.price.toFixed(2)}} {{devise}}</span>
          <span *ngIf="!item.product.price" class="label label-danger"> {{ 'commun.nd' | translate }}</span>
        </td>
        <td>
          {{item?.quantity}}
        </td>
        <td>
          <button *ngIf="item?.product?.favorite==false" style="border:none" type="button"
            (click)="makeFavorite(item?.product?.id)" class="btn btn-sm btn-outline-info">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-star">
              <path
                d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
            </svg>
          </button>
          <button *ngIf="item?.product?.favorite==true" style="border:none" type="button"
            (click)="removeFavorite(item?.product?.id)" class="btn btn-sm btn-outline-info">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-star-fill">
              <path
                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
            </svg>
          </button>
        </td>
        <td>
          <button style="border:none;" type="button" (click)="detailAction(item?.product?.id)"
            class="btn btn-sm btn-outline-info">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
              class="bi bi-info-circle">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path
                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
            </svg>
          </button>
        </td>
        <td>
          <button *ngIf="isAdmin || (userAutorisation == null && userRole != 'writer' && userRole != 'carrier') || userAutorisation.editArticle == true"
            routerLink="/catalog/product/edit/{{item.product.id}}" style="font-size: x-small;  
                    text-align:center; border:none" type="button" class="btn btn-sm btn-outline-primary">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
              class="bi bi-pencil-square">
              <path
                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
              <path fill-rule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
            </svg>
          </button>
        </td>
        <td>
          <button *ngIf="isAdmin || (userAutorisation == null && userRole != 'writer' && userRole != 'carrier') || userAutorisation.deleteArticle == true" style="border:none"
            (click)="deleteAction(item?.product?.id)" type="button" class="btn btn-sm btn-outline-danger">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-trash3">
              <path
                d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
            </svg>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="container">
    <div class="page">
      <ngb-pagination (pageChange)="getPaginatedData($event)" [collectionSize]="total" [(page)]="currentPage" [maxSize]="5" [boundaryLinks]="true"
        [pageSize]="pageSize" [rotate]="true" class="d-flex justify-content-center" style="display:none;">
      </ngb-pagination>
    </div>
    <div class="nbr-page">
      <span class="float-md-left">{{ 'commun.page' | translate }} {{ currentPage }} / {{ nbr_page }}</span>
    </div>
  </div>
</div>
</div>
<!-- <app-catalog-demo [link]="link" [modalVideoDemo]="modalVideoDemo" *ngIf="videoDemo"></app-catalog-demo>  -->
<app-product-detail *ngIf="showDetail" (closed)="closeAction()" (saved)="saved()" [idProduit]="selectedIdProduit">
</app-product-detail>
<app-product-delete *ngIf="showAlert" (closed)="closeAction()" (saved)="saved()" [idProduit]="selectedIdProduit">
</app-product-delete>