import { Component, HostListener, OnInit } from '@angular/core';
import { ClrDatagridSortOrder, ClrDatagridStateInterface } from '@clr/angular';
import jwtDecode from 'jwt-decode';
import { FilterDto } from 'src/app/filter.dto';
import { FilterService } from 'src/app/shared/filter.service';
import { UserService, tokenGetter, getValueFromToken } from 'src/app/user/user.service';
import { WhereFindOperator } from 'src/app/whereFindOperator';
import { Companys } from '../parts';
import { PartsService } from '../parts.service';
import { Autorisation } from 'src/app/user/autorisation/autorisation';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {
  filter: FilterDto<Companys> = new FilterDto<Companys>()
  whereFindOperator = new WhereFindOperator()
  searchString: string;
  showAlert: boolean;
  showDetail: boolean;
  companyArray: Companys[] = [];
  tmpList: Companys[] = [];
  companyId: any;
  loading: boolean
  descSort: ClrDatagridSortOrder;
  companyFilter: boolean;
  id_entreprise: number
  userRole: string
  total: number
  currentPage: number = 1
  nbr_page: number = 1
  pageSize: number = 5
  type: string
  types: string[] = ["nom", "date"]
  date_search: Date
  search_text: string
  isAdmin : boolean 
  userAutorisation : Autorisation = new Autorisation()
  userId : number
  videoDemo: boolean = false
  modalVideoDemo: boolean = false
  link : string =""
  constructor(private userService : UserService, private translate:TranslateService,
     private partsservice: PartsService) { }

  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.userRole = jwtDecode(tokenGetter())["role"]
    this.userId = parseInt(jwtDecode(tokenGetter())["id"])
    this.isAdmin = this.userRole == "admin"
    if(!this.isAdmin){
      this.getUserAutorisation()
    }
    this.getCompanys({});
  }

  typeChanged(type: string) {
    this.type = this.translateItem(type)
  }

  getUserAutorisation(){
    this.userService.getUserAutorisation(this.userId).subscribe(
      data => {
        this.userAutorisation = data[0][0].autorisationId
      }
    )
  }


  refresh(state?: ClrDatagridStateInterface) {
    // if (state) {
    //   this.filter = this.filterService.bind(state);
    // }
    // this.filter.take = this.pageSize;
    // this.filter.select = ["id", "name", "createdAt"];
    // if (!state?.filters) {
    //   this.whereFindOperator.key = null
    //   this.filter.where = { active: true }
    // } else {
    //   this.whereFindOperator.key = this.filterService.dateSearch(state)
    //   this.filter.where = Object.assign(this.filter.where, this.whereFindOperator.key)
    //   this.filter.where = Object.assign(this.filter.where.valueOf(), { active: true })
    // }
    // this.getCompanys(this.filter);
  }
  search_company() {
    this.companyArray = []
    this.tmpList.filter(x => {
      if (x.name.indexOf(this.search_text) != -1) {
        this.companyArray.push(x);
      }
    })
    this.total = this.companyArray.length
    if (this.total > 0) {
      this.nbr_page = Math.ceil(this.total / this.pageSize)
    } else {
      this.nbr_page = 1
    }
  }
  searchWithDate() {
    let date_debut = new Date(this.date_search).setHours(0, 0, 1)
    let date_fin = new Date(this.date_search).setHours(23, 59, 59)
    this.companyArray = []
    this.tmpList.filter(x => {
      let isValid = new Date(x.createdAt).getTime() >= date_debut &&
        new Date(x.createdAt).getTime() <= date_fin
      if (isValid) {
        this.companyArray.push(x);
      }
    })
    this.total = this.companyArray.length
    if (this.total > 0) {
      this.nbr_page = Math.ceil(this.total / this.pageSize)
    } else {
      this.nbr_page = 1
    }
  }
  getCompanys(filter: FilterDto<Companys>) {
    filter.where = { "entrepriseId": this.id_entreprise }
    this.partsservice.getCompanys(filter).subscribe(
      data => {
        this.companyArray = data[0];
        this.tmpList = this.companyArray
        this.total = this.companyArray.length
        if (this.total > 0) {
          this.nbr_page = Math.ceil(this.total / this.pageSize)
        }
      }
    )
  }
  deleteAction(item) {
    this.companyId = item.id
    this.showAlert = true;
  }
  detailAction(item) {
    this.companyId = item.id
    this.showDetail = true;
  }
  closeAction() {
    this.showAlert = false;
    this.showDetail = false;
  }
  openFilter() {
    this.companyFilter = true
  }
  showVideoDemo() {
    this.videoDemo=true
    this.modalVideoDemo=true
  }
  @HostListener('document:click', ['$event'])
  handleCloseDetails(event: MouseEvent) {
    let modal = document.getElementById('modal-content');
    let demoButton = document.getElementById('demo-button');
    let feYoutube = document.getElementById('fa-youtube');
    let demoSpan = document.getElementById('demo-span');
    if (event.target !== modal && event.target !== demoButton && event.target !== feYoutube && event.target !== demoSpan) {
      this.modalVideoDemo=false
      setTimeout(() => {
        this.videoDemo=false
      }, 300);
    }
  }
  translateItem(item: string): string {
    return this.translate.instant(`sharedSales.${item}`);
  }
}