import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { FilterDto } from 'src/app/filter.dto';
import { getValueFromToken, UserService } from '../../user.service';
import { Autorisation } from '../autorisation';
import { User } from '../../user';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-autorisation-detail',
  templateUrl: './autorisation-detail.component.html',
  styleUrls: ['./autorisation-detail.component.css']
})
export class AutorisationDetailComponent implements OnInit {

  userId : number
  id_entreprise : number
  autorisationModel : Autorisation = new Autorisation()
  user : User = new User()
  currentLang: string;
  constructor(private activatedRoute : ActivatedRoute, public translate: TranslateService,
  private userService : UserService) { 
    this.currentLang = localStorage.getItem('currentLang') || 'fr';
    this.translate.use(this.currentLang);
  }

  ngOnInit(): void {
    this.id_entreprise = parseInt(getValueFromToken("idEntreprise"))
    this.activatedRoute.params.subscribe(
      p => {
        this.userId = +p['id']
        this.getUserAutorisation(this.userId);
      }
    )
  }

  print(){
    window.print()
  }

  getUserAutorisation(userId : number){
    let filter = new FilterDto()
    filter.where={"id":userId,"entrepriseId":this.id_entreprise}
    filter.relations=["autorisationId"]
    this.userService.getUser(filter).subscribe(
      data => {
        this.autorisationModel = data[0][0].autorisationId
        this.user = data[0][0]
      }
    )
  }

}
