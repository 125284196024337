<div class="">
    <div class="">
        <div class="entreprise_name">
            {{EntrepriseObject?.name}}
            <div class="address" *ngIf="address">
                {{address || ""}}
            </div>
        </div>
        <div class="header-actions">
            <!-- <a class="nav-link" (click)="print()">
                <clr-icon shape="printer"></clr-icon>
            </a> -->
        </div>
    </div>
    <div class="clr-row">
        <div class="clr-col-5">
            <table class="table table-vertical">
                <tbody>
                    <tr>
                        <th><b>NOM:</b> {{EntrepriseObject?.name}}</th>
                    </tr>
                    <!-- <tr>
                        <th><b>Matricule Fiscale:</b> {{matricule}}</th>
                    </tr> -->
                    <!-- <tr>
                        <th><b>Adresse:</b> {{adresseVille}}</th>
                    </tr> -->
                </tbody>
            </table>
        </div>
        <div class="clr-col-2">
            <table class="table table-vertical">
                <tbody>
                    <tr>
                        <th style="text-align: center;"><b>Ticket N°:</b>{{Order?.real_id}}</th>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="clr-col-5">
            <table class="table table-vertical">
                <tbody>
                    <tr>
                        <th><b>Vendeur : </b> {{vendeur?.name}}</th>
                    </tr>
                    <!-- 
                    <tr>
                        <th><b>EMAIL:</b> {{email}}</th>
                    </tr>
                    <tr>
                        <th><b>SITE:</b> gelivro@gmail.com</th>
                    </tr> -->
                </tbody>
            </table>
        </div>
    </div>
    <!-- <div class="clr-row" id="watermark" *ngIf="show">
        <div class="clr-col-2"></div>
        <div class="clr-col-8">
            <p>Facture Invalide</p>
        </div>
        <div class="clr-col-2"></div>
    </div> -->
    <br>
    <div class="clr-row">
        <div class="clr-col-5">
            <span><b>Client:</b> {{Order?.customerId?.name}}</span><br>
            <span><b>Création à:</b> {{Order?.createdAt | date:'dd/MM/yyyy à HH:mm'}}</span>
        </div>
        <div class="clr-col-2">
        </div>
        <div class="clr-col-5">
            <!-- <span><b>Matricule Fiscale:</b> {{Order?.customerId?.name}}</span><br> -->
            <!-- <span><b>Adresse:</b> {{Order.totalPrice?.toFixed(2)}} DT</span> -->
        </div>
    </div>
    <h3>Liste des produits</h3>
    <table class="table table-noborder">
        <thead>
            <tr>
                <th>Produit</th>
                <th>Quantité</th>
                <th>P.U HT</th>
                <th>TVA (%)</th>
                <th>Remise</th>
                <th class="right">Montant TTC</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of Order?.orderDetails">
                <td>{{item?.productId?.name}}</td>
                <td>{{item?.quantity}}</td>
                <td>{{item?.price}} {{devise}}</td>
                <td>{{item?.productId?.taxId?.percentage}}</td>
                <td>{{item?.discount}}</td>
                <td class="right">{{item?.amount?.toFixed(2)}}</td>
            </tr>
        </tbody>
    </table>
    <!-- <div *ngIf="Order.transactions && Order.transactions >0">
        <h3>Liste des transactions</h3>
        <table class="table table-noborder">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Type de transation</th>
                    <th>Montant</th>
                    <th>Etat</th>
                    <th>Date de création</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of Order.transactions">
                    <td>{{item?.real_id}}</td>
                    <td>
                        <span *ngIf="item.TypeTransaction=='espece'">Espéce</span>
                        <span *ngIf="item.TypeTransaction=='cheque'">par chéque</span>
                        <span *ngIf="item.TypeTransaction=='virement'">par virement</span>
                    </td>
                    <td>{{item?.amount?.toFixed(2)}}</td>
                    <td>
                        <span *ngIf="!item.isCompleted">non valide</span>
                        <span *ngIf="item.isCompleted">valide</span>
                    </td>
                    <td>{{item?.createdAt | date:'dd/MM/yyyy à HH:mm'}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="Order.operations && Order.operations.length >0">
        <h3>Liste des opérations</h3>
        <table class="table table-noborder">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Produit</th>
                    <th>Quantité</th>
                    <th>Date de création</th>
                </tr>
            </thead>
            <tbody *ngFor="let item of Order.operations">
                <tr *ngFor="let op of item.operationDetails">
                    <td>{{op?.real_id}}</td>
                    <td>{{op?.productId?.name}}</td>
                    <td>{{op?.quantity}}</td>
                    <td>{{op?.createdAt | date:'dd/MM/yyyy à HH:mm'}}</td>
                </tr>
            </tbody>
        </table>
    </div> -->
    <div class="clr-row">
        <div class="clr-col-5">
            <table class="table table-vertical">
                <tbody>
                    <tr>
                        <th>Total TTC: {{Order?.totalPrice?.toFixed(2)}} {{devise}}</th>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- <div class="clr-col-2">
        </div>
        <div class="clr-col-5">
            <table class="table table-vertical">
                <tbody>
                    <tr>
                        <th>Signature:</th>
                    </tr>
                </tbody>
            </table>
        </div> -->
    </div>
</div>
<!-- <div class="styleBody">
    <div class="headerr">
        <span><b>Client:</b> {{Order?.customerId?.name}}</span><br>
        <span *ngIf="!Order.isCompleted"><b>Etat:</b> Non Payé</span>
        <span *ngIf="Order.isCompleted"><b>Etat:</b> Payé</span><br>
        <span><b>Date:</b> {{Order.createdAt | date:'dd/MM/yyyy à HH:mm'}}</span><br>
    </div>
    <h3>Liste des produits</h3>

    <br><br>
    <div class="footer"><b>signature:</b>
    </div>
    <br>
    <div class="clr-row">
        <div class="clr-col-10 clr-align-self-start">
            <button class="btn btn-primary dontPrint" (click)="print()">Impression</button>
        </div>
    </div>
</div> -->