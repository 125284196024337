<section>
  <div class="wrapper">
    <div class="demo-wrapper">
      <span>Comment modifier un utilisateur</span>
      <button class="demo-button"  (click)="showVideoDemo()" id="demo-button">
        <i class="fab fa-youtube fa-spin" aria-hidden="true" id="fa-youtube"></i>
        <span class="demo-span" id="demo-span">Demo</span>
      </button>
    </div>
  <div class="alert alert-success" role="alert" *ngIf="success">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle"
            viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path
              d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
          </svg>
        </div>
        <span class="alert-text">
          {{successMsg}}
        </span>
      </div>
    </div>
  </div>
  <div class="alert alert-danger" role="alert" *ngIf="!isMatchingPwd || noImage || !passwordValid || formatInvalid">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            class="bi bi-exclamation-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path
              d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
          </svg>
        </div>
        <span class="alert-text">
          {{erreurMsg}}
        </span>
      </div>
    </div>
  </div>
  <h3 *ngIf="!canResetPwd"> {{ 'commun.mdu' | translate }} </h3>
  <form clrForm *ngIf="stopLoading && !canResetPwd">
    <clr-input-container>
      <label> {{ 'login.VotreNom' | translate }}*</label>
      <input clrInput type="text" [(ngModel)]="userObject.name" name="name" />
    </clr-input-container>

    <clr-input-container>
      <label> {{ 'reg.tel' | translate }}*</label>
      <input clrInput [(ngModel)]="contact.tel" clrInput type="number" name="tel"
        pattern="(([+]?[0-3]{2}?[0-9]{8})||([0-9]{8}))" required />
      <clr-control-error *clrIfError="'required'">{{ 'reg.ob' | translate }} </clr-control-error>
      <clr-control-error *clrIfError="'pattern'">
        {{ 'commun.nui' | translate }}
      </clr-control-error>
    </clr-input-container>

    <!-- <clr-input-container>
      <label>   {{ 'commun.fax' | translate }}</label>
      <input clrInput [(ngModel)]="contact.fax" clrInput type="number" name="fax"
        pattern="(([+]?[0-3]{2}?[0-9]{8})||([0-9]{8}))" required />
    </clr-input-container> -->

    <clr-input-container>
      <label> {{ 'login.VotreEmail' | translate }}*</label>
      <input clrInput type="text" class="form-control" name="email" [(ngModel)]="userObject.email" email />
      <clr-control-error>
        {{ 'commun.ad' | translate }} {{ 'commun.nv' | translate }}
      </clr-control-error>
    </clr-input-container>


    <clr-radio-container clrInline>
      <label> {{ 'commun.Auto' | translate }}*</label>
      <clr-radio-wrapper>
        <input type="radio" clrRadio name="role" required value="responsable" [(ngModel)]="userObject.role" />
        <label> {{ 'sharedSales.responsable' | translate }} </label>
      </clr-radio-wrapper>
      <clr-radio-wrapper *ngIf="showCaissier">
        <input type="radio" clrRadio name="role" required value="writer" [(ngModel)]="userObject.role" />
        <label> {{ 'commun.tre' | translate }} </label>
      </clr-radio-wrapper>
      <clr-radio-wrapper *ngIf="showLivreur">
        <input type="radio" clrRadio name="role" required value="carrier" [(ngModel)]="userObject.role" />
        <label> {{ 'sharedSales.livreur' | translate }} </label>
      </clr-radio-wrapper>
      <clr-control-error>{{ 'reg.ob' | translate }}</clr-control-error>
    </clr-radio-container>

    <div *ngIf="userObject.role == 'writer'">
      <clr-combobox-container>
        <label>{{ 'Caisses.cs1' | translate }} *</label>
        <clr-combobox name="cratesid"  required [clrLoading]="loading"
          (clrInputChange)="onSearchCrates($event)" placeholder="Caisse">
          <clr-options *ngIf="crates">
            <clr-option *ngFor="let item of crates" clrValue="{{item.name}}" (click)="itemChange(item)">
              {{item.name}}
            </clr-option>
          </clr-options>
          <clr-control-error>{{ 'reg.ob' | translate }}</clr-control-error>
        </clr-combobox>
      </clr-combobox-container>
      <clr-control-helper *ngIf="isAutorisedAddCrates">
        <button id="btn_caisse" class="btn btn-link btn-sm" (click)="AddCaissePopUp()">
          {{ 'sharedSales.ajcaisse' | translate }}
        </button>
      </clr-control-helper>
    </div>
    
    <div *ngIf="userObject.role == 'responsable' || userObject.role == 'carrier' ">
      <clr-combobox-container>
        <label *ngIf="userObject.role == 'responsable'">{{ 'Caisses.ptv' | translate }} *</label>
        <label *ngIf="userObject.role=='carrier'">{{ 'sharedSales.ram' | translate }} (pick-up) *</label>
        <clr-combobox name="pointId" required [clrLoading]="loading" (clrInputChange)="onSearchPoint($event)"
          placeholder="point de vente">
          <clr-options *ngIf="points">
            <clr-option *ngFor="let item of points" clrValue="{{item.name}}" (click)="pointChange(item)">
              {{item.name}}
            </clr-option>
          </clr-options>
          <clr-control-error>{{ 'reg.ob' | translate }}</clr-control-error>
        </clr-combobox>
      </clr-combobox-container>
      <clr-control-helper *ngIf="isAutorisedAddSalesPoint">
        <button id="btn_caisse" class="btn btn-link btn-sm" (click)="AddPointPopUp()">
          <span *ngIf="userObject.role == 'responsable'">{{ 'sales.ajPtVente' | translate }}</span>
          <span *ngIf="userObject.role == 'carrier'">{{ 'sharedSales.ajram' | translate }} (pick-up)</span>
        </button>
      </clr-control-helper>
    </div>

    <clr-input-container>
      <label> {{ 'commun.ig' | translate }}:</label>
    </clr-input-container>
    <div class="clr-row clr-align-items-center">
      <!-- <span class="spinner spinner-sm spinner-pos" *ngIf="!userObject.picture"></span> -->
      <div class="clr-col-7 clr-col-md-4  clr-offset-sm-2 " *ngIf="userObject.picture">
        <div class="img">
          <div class="card">
            <div class="card-img container">
              <img [src]="userObject.picture">
            </div>
          </div>
          <br>
        </div>
        <div>
          <cds-file>
            <label></label>
            <input (click)="fileInput.click()" />
          </cds-file>
        </div>
        <div class="clr-row clr-justify-content-rigth">
          <input hidden type="file" #fileInput (change)="picked($event)">
        </div>
      </div>
    </div>



  </form>
  <form clrForm *ngIf="canResetPwd">
    <clr-password-container>
      <label> {{ 'commun.ancm' | translate }}</label>
      <input clrPassword placeholder="Ancien Mot de passe" type="Password" [(ngModel)]="userObject.oldPassword"
        name="password" minlength="4" />
      <clr-control-helper>{{ 'commun.mic' | translate }} </clr-control-helper>
      <clr-control-error *clrIfError="'required'">{{ 'reg.ob' | translate }}!</clr-control-error>
      <clr-control-error *clrIfError="'minlength'">{{ 'commun.mic' | translate }}!</clr-control-error>
    </clr-password-container>

    <clr-password-container>
      <label> {{ 'commun.nvmo' | translate }}</label>
      <input clrPassword placeholder="nouvelle mot de passe" type="Password" [(ngModel)]="userObject.password"
        name="password" minlength="4" />
      <clr-control-helper>{{ 'commun.mic' | translate }}</clr-control-helper>
      <clr-control-error *clrIfError="'required'">{{ 'reg.ob' | translate }}!</clr-control-error>
      <clr-control-error *clrIfError="'minlength'">{{ 'commun.mic' | translate }}!</clr-control-error>
    </clr-password-container>

    <clr-password-container>
      <label> {{ 'commun.cnfrm' | translate }}</label>
      <input clrPassword placeholder="nouvelle mot de passe" type="Password" [(ngModel)]="userObject.repassword"
        name="password" minlength="4" />
      <clr-control-helper>{{ 'commun.mic' | translate }}</clr-control-helper>
      <clr-control-error *clrIfError="'required'">{{ 'reg.ob' | translate }}!</clr-control-error>
      <clr-control-error *clrIfError="'minlength'">{{ 'commun.mic' | translate }}!</clr-control-error>
    </clr-password-container>
    <br>
    <div class="btn-group btn-sm btn-outline-primary reset-group">
      <button type="button" class="btn btn-sm clr-offset-4" (click)="annulerReset()"><small> {{ 'login.tt' | translate
          }}</small></button>
      <button type="button" class="btn btn-sm clr-offset-4" (click)="changePwd()"
        *ngIf="userObject.oldPassword && userObject.password && userObject.repassword">
        <small> {{ 'commun.chpp' | translate }}</small></button>
    </div>
  </form>
  <br>
  <br>
  <div class="btn-group btn-sm btn-outline-primary" *ngIf="!canResetPwd">
    <button class="btn" routerLink="../../">
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
        class="bi bi-arrow-counterclockwise" viewBox="-1 -2 16 16">
        <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z" />
        <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
      </svg> {{ 'login.tt' | translate }}
    </button>
    <button class="btn" (click)="submitAction()"
      *ngIf="userObject.name && userObject.role && userObject.email  && contact.tel">
      <svg id="btn_plus" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
        class="bi bi-plus" viewBox="-1 -4 16 16">
        <path
          d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
      </svg> {{ 'commun.mod' | translate }}
    </button>
    <button type="button" class="btn btn-sm clr-offset-4" [clrLoading]="validateBtnState"
      (click)="openResetPwd()"><small> {{ 'commun.chpp' | translate }} </small></button>
  </div>
  </div>
</section>
<!-- <app-user-demo [link]="link" [modalVideoDemo]="modalVideoDemo" *ngIf="videoDemo"></app-user-demo>  -->
<app-add-caisse-modal *ngIf="showAddCaisse" (saved)="getCaisses()" (closed)="CloseAction()"></app-add-caisse-modal>