<section>
    <div class="wrapper">
    <div class="demo-wrapper">
      <span>{{ 'demo.vlrs' | translate }}</span>
      <button class="demo-button"  (click)="showVideoDemo()" id="demo-button">
        <i class="fab fa-youtube fa-spin" aria-hidden="true" id="fa-youtube"></i>
        <span class="demo-span" id="demo-span">Demo</span>
      </button>
    </div>
    <h3>{{ 'Comptabilité.livereso' | translate }} </h3>

    <div>
        <table class="table table-hover" style="margin-top: 40px;">

            <thead>
                <tr>
                    <th style="text-align: center">{{ 'Comptabilité.idvente' | translate }} </th>
                    <th style="text-align: center">{{ 'Comptabilité.datecrea' | translate }}</th>
                    <th style="text-align: center">{{ 'Comptabilité.ttvente' | translate }}</th>
                    <th style="text-align: center">{{ 'Comptabilité.valret' | translate }}1%</th>
                    <th style="text-align: center">{{ 'Comptabilité.valret' | translate }}25%</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of orders">
                    <td scope="row">
                        {{item.order.real_id}}
                    </td>
                    <td>
                        {{item.order.createdAt | date:' dd-MM-yyyy à HH:mm'}}
                    </td>
                    <td>
                        {{item.order.totalPrice.toFixed(2)}} {{devise}}
                    </td>
                    <td>
                        <span *ngIf="item.retenue1!=-1">{{(item.order.totalPrice
                            *(item.order.retenues[item.retenue1]?.value/100)).toFixed(2)}}
                            {{devise}}</span>
                        <span *ngIf="item.retenue1 == -1">0.00 {{devise}}</span>
                    </td>
                    <td>
                        <span *ngIf="item.retenue2 != -1">
                            {{item.retenue2.toFixed(2)}}
                            {{devise}}
                        </span>
                        <span *ngIf="item.retenue2 == -1">0.00 {{devise}}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    </div>
</section>
<app-app-demo [link]="link" [modalVideoDemo]="modalVideoDemo" *ngIf="videoDemo"></app-app-demo> 
