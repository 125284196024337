<div *ngIf="showSuccess">
    <div class="container">
        <div class="header-msg">
            <b>{{ 'commun.cmpac' | translate }}</b>
        </div>
        <div class="success-message">
            <p>{{ 'commun.cmpacsu' | translate }}</p>
        </div>
    </div>
</div>

<div *ngIf="showAlert">
    <div class="container">
        <div class="header-msg">
            {{ 'commun.cmpdac' | translate }}
        </div>
        <div class="success-message">
            <p>{{ 'commun.cmpdacsu' | translate }} </p>
        </div>
    </div>
</div>