import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FilterDto } from 'src/app/filter.dto';
import { Order } from 'src/app/sales/order/order';
import { OrderService } from 'src/app/sales/order/order.service';
import { Entreprise } from 'src/app/user/entreprise';
import { UserService } from 'src/app/user/user.service';

@Component({
  selector: 'app-print-ticket-sales',
  templateUrl: './print-ticket-sales.component.html',
  styleUrls: ['./print-ticket-sales.component.css']
})
export class PrintTicketSalesComponent implements OnInit {
  @Input() Order: any
  @Input() EntrepriseObject: any
  @Input() address: any
  @Input() vendeur: any
  id_entreprise: number
  orderId: Order
  devise: string
  url: string
  data: string
  filter: FilterDto = new FilterDto()
  user_created: any

  constructor(private userService: UserService, private router: Router,
    private orderService: OrderService
  ) { }

  ngOnInit(): void {
    // this.id_entreprise = parseInt(this.getCookie("idEntreprise"))
    // this.getEntrepriseInfo();
    // this.url = this.router.url;
    // let tabRoutes = this.url.split("/");
    // this.data = tabRoutes[3]
    // //this.id_order = parseInt(this.data)
    // this.getOrder()
    // setTimeout(
    //   x => {
    //     this.getUserInfo()
    //   }, 2000
    // )
  }
  // getOrder() {
  //   this.filter.relations = ["customerId", "orderDetails", "orderDetails.productId", "orderDetails.productId.taxId", "transactions", "transactions.transactionTypeId",
  //     "transactions.bankId", "operations", "operations.operationDetails", "operations.operationDetails.productId"];
  //   this.filter.select = ["id", "real_id", "totalPrice", "isCompleted", "createdBy", "createdAt", "description"];
  //   //this.filter.where = { "id": this.id_order, "entrepriseId": this.id_entreprise };
  //   this.orderService.getOrders(this.filter).subscribe(
  //     data => {
  //       this.orderId = data[0][0];
  //     },
  //     err => console.error('Observer got an error: ', err),
  //   );
  // }
  // getEntrepriseInfo() {
  //   let filter: FilterDto = new FilterDto()
  //   filter.where = { "id": this.id_entreprise }
  //   filter.relations = ["deviseId"]
  //   this.userService.getAllEntreprises(filter).subscribe(
  //     data => {
  //       this.entrepriseObject = data[0][0];
  //       this.devise = this.entrepriseObject.deviseId.code
  //     }
  //   )
  // }
  // getUserInfo() {
  //   let filter = new FilterDto()
  //   filter.where = { "id": this.orderId.createdBy }
  //   filter.relations = ["cratesId", "cratesId.pointid", "cratesId.pointid.adress"]
  //   this.userService.getAllUsers(filter).subscribe(
  //     data => {
  //       this.user_created = data[0][0]
  //       this.address = this.user_created.cratesId.pointid.adress[0].number +
  //         " " + this.user_created.cratesId.pointid.adress[0].street + " " +
  //         this.user_created.cratesId.pointid.adress[0].city + " " +
  //         this.user_created.cratesId.pointid.adress[0].geoCode + " "
  //         + this.user_created.cratesId.pointid.adress[0].city
  //     }
  //   )
  // }
  // getCookie(cookieName: string) {
  //   var cookieValue: string
  //   var ca = document.cookie.split(';');
  //   if (cookieName != undefined) {
  //     if (ca.length > 0) {
  //       ca.forEach(element => {
  //         if (element.includes(cookieName + "=")) {
  //           cookieValue = element.replace(cookieName + "=", "")
  //           return cookieValue
  //         }
  //       });
  //     }
  //   }
  //   return cookieValue
  // }
}
