<div class="modal">
  <div class="modal-dialog" role="dialog" aria-hidden="true">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">{{ 'Relation.Fournisseurs.def' | translate }}</h3>
        <svg xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;" (click)="closeAction()" width="20" height="20"
          fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
          <path
            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
        </svg>
      </div>
      <div class="modal-body">
        <table class="table">
          <thead>
            <tr>
              <th>{{ 'Relation.Fournisseurs.nfr' | translate }}</th>
              <th>{{ 'commun.mf' | translate }}</th>
              <th>{{ 'Points.desc' | translate }}</th>
              <th *ngIf="providerObject?.createdBy">{{ 'sharedSales.crpar' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{providerObject?.name}}</td>
              <td *ngIf="providerObject.mf">{{providerObject.mf}}</td>
              <td *ngIf="!providerObject.mf"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></td>
              <td *ngIf="providerObject?.description">{{providerObject?.description}}</td>
              <td *ngIf="!providerObject?.description"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></td>
              <td *ngIf="providerObject.createdBy && userAdd">{{userAdd?.name}} le {{providerObject?.createdAt | date:
                'dd/MM/yyyy'}}
                <br><span *ngIf="userEdit && providerObject?.updatedBy">{{ 'Relation.Fournisseurs.etmo' | translate }} {{userEdit?.name}} le
                  {{providerObject?.updatedAt | date: 'dd/MM/yyyy'}} </span>
              </td>
            </tr>
          </tbody>
        </table>
        <h3 *ngIf="providerObject?.contacts?.length>0">{{ 'sales.contacts' | translate }}</h3>
        <div class="overflow-x" *ngIf="providerObject?.contacts?.length>0">
          <table class="table">
            <thead>
              <tr>
                <th>{{ 'login.VotreNom' | translate }}</th>
                <th>{{ 'commun.Tel' | translate }}</th>
                <!-- <th>Fax</th> -->
                <th>{{ 'login.VotreEmail' | translate }}</th>
              </tr>
            </thead>
            <tbody *ngFor="let item of providerObject?.contacts">
              <tr>
                <td *ngIf="item.firstName">{{item.firstName}}</td>
                <td *ngIf="!item.firstName"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></td>

                <td *ngIf="item.tel">{{item.tel}}</td>
                <td *ngIf="!item.tel"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></td>

                <!-- <td *ngIf="item.fax">{{item.fax}}</td>
                <td *ngIf="!item.fax"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></td> -->

                <td *ngIf="item.email">{{item.email}}</td>
                <td *ngIf="!item.email"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></td>

              </tr>
            </tbody>
          </table>
        </div>
        <h3 *ngIf="providerObject?.adress?.length>0">{{ 'sales.adresses' | translate }}</h3>
        <div class="overflow-x" *ngIf="providerObject?.adress?.length>0">
          <table class="table">
            <thead>
              <tr>
                <th>{{ 'sales.numero' | translate }}</th>
                <th>{{ 'sales.route' | translate }}</th>
                <th>{{ 'sales.codepostale' | translate }}</th>
                <th>{{ 'sales.ville' | translate }}</th>
                <th>{{ 'sales.gouvernorat' | translate }}</th>
              </tr>
            </thead>
            <tbody *ngFor="let item of providerObject.adress">
              <tr>
                <td *ngIf="item.number">{{item.number}}</td>
                <td *ngIf="!item.number"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></td>

                <td *ngIf="item.street">{{item.street}}</td>
                <td *ngIf="!item.street"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></td>


                <td *ngIf="item.geoCode">{{item.geoCode}}</td>
                <td *ngIf="!item.geoCode"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></td>

                <td *ngIf="item.city">{{item.city}}</td>
                <td *ngIf="!item.city"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></td>

                <td *ngIf="item.state">{{item.state}}</td>
                <td *ngIf="!item.state"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></td>

              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-backdrop" aria-hidden="true"></div>