<app-nav></app-nav>

<section>
  <h3><b> &nbsp; {{ 'reg.cr' | translate }}</b></h3>
  <br>
</section>
<div class="clr-row">
  <div class="clr-col-5">

  </div>
</div>
<div class="login-wrapper">
  <form clrStepper>
    <clr-stepper-panel ngModelGroup="step1">
      <clr-step-title> {{ 'Paramtrage.Utilisateurs.u' | translate }}</clr-step-title>
      <clr-step-content *clrIfExpanded>
        <div class="alert alert-success" role="alert" *ngIf="showAlert && !usedMail">
          <div class="alert-items">
            <div class="alert-item static">
              <div class="alert-icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-check-circle" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path
                    d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                </svg>
              </div>
              <span class="alert-text">
                {{ 'login.sc' | translate }}
              </span>
            </div>
          </div>
        </div>
        <div class="alert alert-danger" role="alert" *ngIf="usedMail">
          <div class="alert-items">
            <div class="alert-item static">
              <div class="alert-icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-check-circle" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path
                    d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                </svg>
              </div>
              <span class="alert-text">
                {{erreurMsg}}
              </span>
            </div>
          </div>
        </div>
        <form clrForm [formGroup]="signForm">
          <clr-input-container>
            <label> {{ 'login.np' | translate }}*</label>
            <input clrInput type="text" formControlName="name" name="name" [(ngModel)]="objectUser.name" required />
            <clr-control-error *clrIfError="'required'">{{ 'reg.ob' | translate }}</clr-control-error>
          </clr-input-container>
          <clr-input-container>
            <label>{{ 'login.VotreEmail' | translate }} *</label>
            <input clrInput type="text" (input)="verifMail()" formControlName="email" name="email"
              [(ngModel)]="objectUser.email" required email />
          </clr-input-container>
          <clr-input-container>
            <label>{{ 'commun.Tel' | translate }} *</label>
            <input clrInput type="text" formControlName="tel" name="tel" [(ngModel)]="objectUser.tel" required />
          </clr-input-container>
          <clr-input-container>
            <label>{{ 'commun.mf' | translate }} *</label>
            <input clrInput type="text" formControlName="mf" name="mf" [(ngModel)]="objectUser.mf" required />
          </clr-input-container>
          <clr-combobox-container>
            <label>{{ 'sharedSales.devise' | translate }} *</label>
            <clr-combobox name="deviseId" required [clrLoading]="loading" (clrInputChange)="onSearchChange($event)"
              placeholder="{{ 'login.sd' | translate }}">
              <clr-options *ngIf="devises">
                <clr-option *ngFor="let item of devises;" clrValue={{item.name}} (click)="itemChange(item)">
                  {{item?.name}}
                </clr-option>
              </clr-options>
              <clr-control-error>{{ 'reg.ob' | translate }}</clr-control-error>
            </clr-combobox>
          </clr-combobox-container>

          <clr-password-container>
            <label>{{ 'login.MDP' | translate }}</label>
            <input clrPassword formControlName="password" name="password" [(ngModel)]="objectUser.password" />
            <clr-control-error *clrIfError="'required'">{{ 'reg.ob' | translate }}</clr-control-error>
            <clr-control-error *clrIfError="'minlength'">{{ 'reg.rq' | translate }}</clr-control-error>
            <clr-control-error *clrIfError="'maxlength'">{{ 'reg.rrq' | translate }}</clr-control-error>
          </clr-password-container>
          <clr-password-container>
            <label>{{ 'reg.cp' | translate }}</label>
            <input clrPassword formControlName="repassword" [(ngModel)]="repassword" />
            <clr-control-error *clrIfError="'required'">{{ 'reg.cc' | translate }}</clr-control-error>
            <clr-control-error *clrIfError="'mismatch'">{{ 'reg.ps' | translate }}</clr-control-error>
          </clr-password-container>
        </form>
        <br>
        <button class="btn btn-success btn-sm" type="submit" (click)="submit()"
          [disabled]="!objectUser?.name || !objectUser?.email || !objectUser?.tel  || !objectUser?.mf || !objectUser?.deviseId || !objectUser?.password  || objectUser?.password != repassword || usedMail ">{{
          'reg.e' | translate }}</button>
      </clr-step-content>
    </clr-stepper-panel>
  </form>
</div>