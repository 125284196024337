<section>
  <div class="wrapper">
  <div class="demo-wrapper">
      <span>Comment ajouter un fournisseur</span>
      <button class="demo-button"  (click)="showVideoDemo()" id="demo-button">
        <i class="fab fa-youtube fa-spin" aria-hidden="true" id="fa-youtube"></i>
        <span class="demo-span" id="demo-span">Demo</span>
      </button>
    </div>
  <h3>{{ 'Relation.Fournisseurs.ajf' | translate }}</h3>
  <div *ngIf="showAlert" class="alert alert-success" role="alert">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle"
            viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path
              d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
          </svg>
        </div>
        <span class="alert-text">
          {{ 'Relation.Fournisseurs.ajfs' | translate }}
        </span>
      </div>
    </div>
  </div>
  <div *ngIf="usedMail" class="alert alert-danger" role="alert">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle"
            viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path
              d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
          </svg>
        </div>
        <span class="alert-text">
          {{ 'Relation.Fournisseurs.admu' | translate }}
        </span>
      </div>
    </div>
  </div>
  <br>
  <form clrStepper clrInitialStep={{initaStep}} (ngSubmit)="submit()" style="margin-top: 10px;">
    <clr-stepper-panel ngModelGroup="step1">
      <clr-step-title>{{ 'sharedSales.fournisseur' | translate }}</clr-step-title>
      <clr-step-content>
        <form clrForm>
          <clr-input-container>
            <label>{{ 'login.VotreNom' | translate }} *</label>
            <input name="name" clrInput [(ngModel)]="providerObject.name" required (keypress)="keyPressNom($event)" pattern="^([0-9]*[1-9a-zA-Z][0-9a-zA-Z]*[^]*(\.[0-9]+)?|[0]+\.[0-9]*[1-9][0-9]*)$"/>
            <clr-control-error *clrIfError="'required'"> {{ 'reg.ob' | translate }} </clr-control-error>
          </clr-input-container>
          <clr-input-container>
            <label>{{ 'commun.mf' | translate }} *</label>
            <input name="mf" clrInput [(ngModel)]="providerObject.mf" required (keypress)="keyPressMF($event)" (keyup)="onKeyMF($event)" pattern="^([0-9]*[1-9a-zA-Z][0-9a-zA-Z]*(\.[0-9]+)?|[0]+\.[0-9]*[1-9][0-9]*)$"/>
            <clr-control-error *clrIfError="'required'"> {{ 'reg.ob' | translate }} </clr-control-error>
            <clr-control-error *clrIfError="'pattern'"> {{ 'commun.mfi' | translate }} </clr-control-error>
          </clr-input-container>
          <clr-textarea-container>
            <label>{{ 'Points.desc' | translate }}</label>
            <textarea clrTextarea name="description" [(ngModel)]="providerObject.description" (keyup)="onkeyAdress($event)" pattern="^([0-9]*[1-9a-zA-Z][0-9a-zA-Z]*[^]*(\.[0-9]+)?|[0]+\.[0-9]*[1-9][0-9]*)$"></textarea>
            <clr-control-error *clrIfError="'pattern'"> {{ 'commun.di' | translate }} </clr-control-error>
          </clr-textarea-container>
        </form>
        <button clrStepButton="next" *ngIf="providerObject.name && providerObjectMF && providerObjectDe">Suivant</button>
      </clr-step-content>
    </clr-stepper-panel>
    <clr-stepper-panel ngModelGroup="step2">
      <clr-step-title>{{ 'sales.contacts' | translate }}</clr-step-title>
      <clr-step-content>
        <h3>{{ 'Relation.Fournisseurs.ctf' | translate }}</h3>
        <div *ngIf="showDetail" class="alert alert-success" role="alert">
          <div class="alert-items">
            <div class="alert-item static">
              <div class="alert-icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-check-circle" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path
                    d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                </svg>
              </div>
              <span class="alert-text">
                {{ 'Relation.Fournisseurs.ajcs' | translate }}
              </span>
            </div>
          </div>
        </div>
        <form clrForm>

          <!-- <clr-input-container>
            <label>Nom *</label>
            <input clrInput [(ngModel)]="contact.firstName" name="firstName" />
          </clr-input-container> -->

          <clr-input-container>
            <label>{{ 'commun.Tel' | translate }} *</label>
            <input type="number" clrInput [(ngModel)]="contact.tel" name="tel" required
              (keyup)="onkeyTel($event)" pattern="(([+]?[0-3]{2}?[0-9]{8})||([0-9]{8}))" />
            <clr-control-error *clrIfError="'required'">{{ 'sales.numeroteleobli' | translate }}</clr-control-error>
            <clr-control-error *clrIfError="'pattern'">{{ 'sales.numeroteleinco' | translate }}</clr-control-error>
          </clr-input-container>

          <!-- <clr-input-container>
            <label>Fax</label>
            <input type="number" clrInput [(ngModel)]="contact.fax" name="fax" pattern="[0-9]{8}" />
            <clr-control-error>Numéro de fax est incorrect </clr-control-error>
          </clr-input-container> -->

          <clr-input-container>
            <label>{{ 'login.VotreEmail' | translate }}</label>
            <input clrInput type="text" class="form-control" name="email" [(ngModel)]="contact.email" #email="ngModel" 
              pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" [ngClass]="{ 'valid': email.valid}" email (keyup)="onkeyMail($event)"/>
            <clr-control-error *clrIfError="'pattern'">{{ 'sales.Adremailvali' | translate }}</clr-control-error>
          </clr-input-container>
          <br>
          <button class="btn btn-sm" type="submit" (click)="addContact()" *ngIf="contactTel && contactMail">{{ 'sharedSales.ajcon' | translate }}</button>
          
          <clr-datagrid *ngIf="showDetail && contactArray.length > 0">
            <clr-dg-column>
              {{ 'Relation.Fournisseurs.nfr' | translate }}
            </clr-dg-column>
            <clr-dg-column>
              {{ 'commun.Tel' | translate }}
            </clr-dg-column>
            <!-- <clr-dg-column>
              Fax
            </clr-dg-column> -->
            <clr-dg-column>
              {{ 'login.VotreEmail' | translate }}
            </clr-dg-column>
            <clr-dg-row *ngFor="let item of contactArray">
              <clr-dg-action-overflow>
                <button class="action-item" (click)="deleteContact(item)">{{ 'pack.suppression' | translate }}</button>
              </clr-dg-action-overflow>

              <clr-dg-cell *ngIf="providerObject.name">{{providerObject.name}}</clr-dg-cell>
              <clr-dg-cell *ngIf="!providerObject.name"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></clr-dg-cell>

              <clr-dg-cell *ngIf="item.tel">{{item.tel}}</clr-dg-cell>
              <clr-dg-cell *ngIf="!item.tel"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></clr-dg-cell>

              <!-- <clr-dg-cell *ngIf="item.fax">{{item.firstName}}</clr-dg-cell>
              <clr-dg-cell *ngIf="!item.fax"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></clr-dg-cell> -->

              <clr-dg-cell *ngIf="item.email">{{item.email}}</clr-dg-cell>
              <clr-dg-cell *ngIf="!item.email"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></clr-dg-cell>
            </clr-dg-row>
          </clr-datagrid>
        </form>
        <button clrStepButton="next" (click)="loadAdresse()"
          *ngIf="contact.tel && contact.firstName || contactArray.length > 0">{{ 'commun.sv' | translate }}</button>
      </clr-step-content>
    </clr-stepper-panel>
    <clr-stepper-panel ngModelGroup="step3">
      <clr-step-title>{{ 'commun.ad' | translate }}</clr-step-title>
      <clr-step-content>
        <h3>{{ 'Relation.Fournisseurs.adf' | translate }}</h3>
        <div *ngIf="showDetailAdr" class="alert alert-success" role="alert">
          <div class="alert-items">
            <div class="alert-item static">
              <div class="alert-icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-check-circle" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path
                    d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                </svg>
              </div>
              <span class="alert-text">
                {{ 'sales.ajoutaddresse' | translate }}
              </span>
            </div>
          </div>
        </div>
        <form clrForm>
          <clr-input-container>
            <label>{{ 'commun.num' | translate }} *</label>
            <input type="number" clrInput [(ngModel)]="adresse.number" name="adrName" required (keypress)="keyPressNum($event)" min="0" pattern="^(?!-?0+$|-$)\d*$"/>
            <clr-control-error *clrIfError="'required'"> {{ 'reg.ob' | translate }} </clr-control-error>
            <clr-control-error *clrIfError="'pattern'"> {{ 'commun.ni' | translate }} </clr-control-error>
          </clr-input-container>
          <clr-input-container>
            <label>{{ 'commun.rt' | translate }} *</label>
            <input type="text" clrInput [(ngModel)]="adresse.street" name="street" required (keyup)="onkeyStreet($event)" pattern="^([0-9]*[1-9a-zA-Z][0-9a-zA-Z]*[^]*(\.[0-9]+)?|[0]+\.[0-9]*[1-9][0-9]*)$"/>
            <clr-control-error *clrIfError="'required'"> {{ 'reg.ob' | translate }} </clr-control-error>
            <clr-control-error *clrIfError="'pattern'"> {{ 'commun.ri' | translate }} </clr-control-error>
          </clr-input-container>
          <clr-input-container>
            <label>{{ 'commun.cdp' | translate }}</label>
            <input type="number" clrInput [(ngModel)]="adresse.geoCode" name="geoCode" (keypress)="keyPressNum($event)" (keyup)="onkeyCodePostal($event)" pattern="^(?!-?0+$|-$)\d*$"/>
            <clr-control-error *clrIfError="'pattern'"> {{ 'commun.cpi' | translate }} </clr-control-error>  
          </clr-input-container>
          <clr-input-container>
            <label>{{ 'sales.ville' | translate }} *</label>
            <input type="text" clrInput [(ngModel)]="adresse.city" name="city" required (keyup)="onkeyCity($event)" pattern="^([0-9]*[1-9a-zA-Z][0-9a-zA-Z]*[^]*(\.[0-9]+)?|[0]+\.[0-9]*[1-9][0-9]*)$"/>
            <clr-control-error *clrIfError="'required'"> {{ 'reg.ob' | translate }} </clr-control-error>
            <clr-control-error *clrIfError="'pattern'"> {{ 'commun.vi' | translate }} </clr-control-error>
          </clr-input-container>
          <clr-input-container>
            <label>{{ 'sales.gouvernorat' | translate }}</label>
            <input type="text" clrInput [(ngModel)]="adresse.state" name="state" (keyup)="onkeyGovernorat($event)" pattern="^([0-9]*[1-9a-zA-Z][0-9a-zA-Z]*[^]*(\.[0-9]+)?|[0]+\.[0-9]*[1-9][0-9]*)$"/>
            <clr-control-error *clrIfError="'pattern'"> {{ 'commun.gi' | translate }} </clr-control-error>
          </clr-input-container>
          <br>
          <button class="btn btn-sm" type="submit" (click)="addAdresse()"
            *ngIf="adresse.number && AdressStreet && AdressPostal && AdressCity && AdressGouvernorat">
            {{ 'sharedSales.ajouter' | translate }}</button>
          <clr-datagrid *ngIf="showDetailAdr && adressArray.length > 0">
            <clr-dg-column>
              {{ 'sales.numero' | translate }}
            </clr-dg-column>
            <clr-dg-column>
              {{ 'sales.route' | translate }}
            </clr-dg-column>
            <clr-dg-column>
              {{ 'sales.codepostale' | translate }}
            </clr-dg-column>
            <clr-dg-column>
              {{ 'sales.ville' | translate }}
            </clr-dg-column>
            <clr-dg-column>
              {{ 'sales.gouvernorat' | translate }}
            </clr-dg-column>
            <clr-dg-row *ngFor="let item of adressArray">
              <clr-dg-action-overflow>
                <button class="action-item" (click)="deleteAdresse(item)">{{ 'pack.suppression' | translate }}</button>
              </clr-dg-action-overflow>
              <clr-dg-cell *ngIf="item.number">{{item.number}}</clr-dg-cell>
              <clr-dg-cell *ngIf="!item.number"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></clr-dg-cell>

              <clr-dg-cell *ngIf="item.street">{{item.street}}</clr-dg-cell>
              <clr-dg-cell *ngIf="!item.street"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></clr-dg-cell>

              <clr-dg-cell *ngIf="item.geoCode">{{item.geoCode}}</clr-dg-cell>
              <clr-dg-cell *ngIf="!item.geoCode"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></clr-dg-cell>

              <clr-dg-cell *ngIf="item.city">{{item.city}}</clr-dg-cell>
              <clr-dg-cell *ngIf="!item.city"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></clr-dg-cell>

              <clr-dg-cell *ngIf="item.state">{{item.state}}</clr-dg-cell>
              <clr-dg-cell *ngIf="!item.state"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></clr-dg-cell>
            </clr-dg-row>
          </clr-datagrid>
        </form>
        <button clrStepButton="next"
          *ngIf="adresse.number && AdressStreet && AdressPostal && AdressCity && AdressGouvernorat || adressArray.length > 0">Suivant</button>
      </clr-step-content>
    </clr-stepper-panel>
    <clr-stepper-panel ngModelGroup="step4">
      <clr-step-title>{{ 'commun.rc' | translate }}</clr-step-title>
      <clr-step-content>
        <h3 *ngIf="providerObject">{{ 'Relation.Fournisseurs.detf' | translate }}</h3>
        <div class="overflow-x" *ngIf="providerObject.name">
          <table class="table">
            <thead>
              <tr>
                <th>{{ 'Relation.Fournisseurs.nfr' | translate }}</th>
                <th>{{ 'commun.mf' | translate }}</th>
                <th>{{ 'Points.desc' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{providerObject.name}}</td>
                <td *ngIf="providerObject.mf">{{providerObject.mf}}</td>
                <td *ngIf="!providerObject.mf"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></td>
                <td *ngIf="providerObject.description">{{providerObject.description}}</td>
                <td *ngIf="!providerObject.description"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></td>
              </tr>
            </tbody>
          </table>
        </div>

        <h3 *ngIf="providerObject.contacts">{{ 'sales.contacts' | translate }}</h3>
        <div class="overflow-x" *ngIf="providerObject.contacts">
          <table class="table">
            <thead>
              <tr>
                <th>{{ 'login.VotreNom' | translate }}</th>
                <th>{{ 'commun.Tel' | translate }}</th>
                <!-- <th>Fax</th> -->
                <th>{{ 'login.VotreEmail' | translate }}</th>
              </tr>
            </thead>
            <tbody *ngFor="let item of providerObject.contacts">
              <tr>
                <td *ngIf="item.firstName">{{item.firstName}}</td>
                <td *ngIf="!item.firstName"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></td>

                <td *ngIf="item.tel">{{item.tel}}</td>
                <td *ngIf="!item.tel"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></td>

                <!-- <td *ngIf="item.fax">{{item.fax}}</td>
                <td *ngIf="!item.fax"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></td> -->

                <td *ngIf="item.email">{{item.email}}</td>
                <td *ngIf="!item.email"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></td>

              </tr>
            </tbody>
          </table>
        </div>
        <h3 *ngIf="providerObject.adress">{{ 'sales.adresses' | translate }}</h3>
        <div class="overflow-x" *ngIf="providerObject.adress">
          <table class="table">
            <thead>
              <tr>
                <th>{{ 'sales.numero' | translate }}</th>
                <th>{{ 'sales.route' | translate }}</th>
                <th>{{ 'sales.codepostale' | translate }}</th>
                <th>{{ 'sales.ville' | translate }}</th>
                <th>{{ 'sales.gouvernorat' | translate }}</th>
              </tr>
            </thead>
            <tbody *ngFor="let item of providerObject.adress">
              <tr>
                <td *ngIf="item.number">{{item.number}}</td>
                <td *ngIf="!item.number"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></td>

                <td *ngIf="item.street">{{item.street}}</td>
                <td *ngIf="!item.street"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></td>


                <td *ngIf="item.geoCode">{{item.geoCode}}</td>
                <td *ngIf="!item.geoCode"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></td>

                <td *ngIf="item.city">{{item.city}}</td>
                <td *ngIf="!item.city"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></td>

                <td *ngIf="item.state">{{item.state}}</td>
                <td *ngIf="!item.state"><span class="label label-warning">{{ 'commun.nd' | translate }}</span></td>

              </tr>
            </tbody>
          </table>
        </div>
        <br>
        <button class="btn btn-outline-primary" clrStepButton="submit" *ngIf="providerObject.name && !addNew">
          <svg id="btn_plus" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            class="bi bi-plus" viewBox="-1 -4 16 16">
            <path
              d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
          </svg> {{ 'reg.e' | translate }}
        </button>
      </clr-step-content>
    </clr-stepper-panel>
  </form>

  <div class="btn-group btn-outline-primary">
    <button class="btn" routerLink="../">
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
        class="bi bi-arrow-counterclockwise" viewBox="-1 -2 16 16">
        <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z" />
        <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
      </svg> {{ 'pack.retour' | translate }}
    </button>
    <button class="btn" (click)="resetForm()" *ngIf="addNew">
      <svg id="btn_plus" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
        class="bi bi-plus" viewBox="-1 -4 16 16">
        <path
          d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
      </svg> {{ 'sales.creernouveau' | translate }}
    </button>
  </div>
</div>
</section>
<!-- <app-parts-demo [link]="link" [modalVideoDemo]="modalVideoDemo" *ngIf="videoDemo"></app-parts-demo>  -->
