<section>
  <h3> {{ 'commun.du' | translate }} </h3>
  <br>
  <div class="clr-row">
    <div class="clr-col-3">
      <span><b> {{ 'commun.id' | translate }}</b></span><br>
      <span><b> {{ 'login.VotreNom' | translate }}</b></span><br>
      <span><b> {{ 'login.VotreEmail' | translate }}</b></span><br>
      <span><b> {{ 'commun.Auto' | translate }}</b></span><br>
      <span *ngIf="user.role=='writer'"><b>{{ 'Caisses.cs1' | translate }}</b><br></span>
      <span *ngIf="user.role=='writer' || user.role=='responsable'"><b>{{ 'Caisses.ptv' | translate }}</b><br></span>
      <span><b> {{ 'commun.cp' | translate }}</b></span><br>
      <span *ngIf="user.updatedBy"><b> {{ 'commun.mdp' | translate }} </b></span><br>
      <!-- <span *ngIf="user.caissier"></span> -->
    </div>
    <div class="clr-col">
      <span>{{user.real_id}}</span><br>
      <span>{{user.name}}</span><br>
      <span>{{user.email}}</span><br>
      <span *ngIf="user.role=='responsable'"><span class="label label-info">{{ 'sharedSales.responsable' | translate }}
        </span></span>
      <span *ngIf="user.role == 'carrier'"><span class="label label-info">{{ 'sharedSales.livreur' | translate }}</span></span>
      <span *ngIf="user.role=='writer'"><span class="label label-warning"> {{ 'commun.tre' | translate }}</span></span>
      <span *ngIf="user.role=='admin'"><span class="label label-success"> {{ 'commun.adm' | translate
          }}</span></span><br>
      <span *ngIf="user.role=='writer'">{{user.cratesId.name}}<br></span>
      <span *ngIf="user.role=='writer'">{{user.cratesId.pointid.name}}<br></span>
      <span *ngIf="user.role=='responsable'">{{user.pointId.name}}<br></span>
      <span *ngIf="user.createdBy && userAdd">
        <strong>{{userAdd.name}}</strong> à <strong>{{user.createdAt | date:
          'dd/MM/yyyy HH:mm:ss'}}</strong>
      </span>
      <br>
      <span *ngIf="user.updatedBy && userEdit">
        <strong>{{userEdit.name}}</strong> à <strong>{{user.updatedAt |
          date: 'dd/MM/yyyy HH:mm:ss' }}</strong>
      </span>
    </div>
  </div>
  <br>

  <div class="btn-group btn-sm btn-outline-primary ">
    <button class="btn" routerLink="../../">
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
        class="bi bi-arrow-counterclockwise" viewBox="-1 -2 16 16">
        <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z" />
        <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
      </svg> {{ 'login.tt' | translate }}
    </button>
    <button class="btn" routerLink="/user/users/edit-user/{{user.id}}" *ngIf="user.id!=currentUser">
      <clr-icon shape="pencil"></clr-icon> {{ 'commun.mod' | translate }}
    </button>
    <button class="btn" (click)="deleteAction()" *ngIf="user.id!=currentUser">
      <clr-icon shape="trash"></clr-icon> {{ 'commun.sp' | translate }}
    </button>
  </div>
</section>
<app-delete-user *ngIf="showAlert" (closed)="closeAction()" [userObject]="user"></app-delete-user>