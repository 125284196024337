// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // api: 'http://localhost:3000' ,
  // api: "https://dev.api.inveep.com",
  api: "https://dev-api.inveep.com",
  emailSociete: "gelivro.startup@gmail.com",
  // apiFront: "https://dev.app.inveep.com",
  apiFront: "https://dev-app.inveep.com",
  // apiFront: 'http://localhost:4200',
  path_template: 'uploads',
  // frontAdmin : "https://dev.admin.inveep.com",
  frontAdmin : "https://dev-admin.inveep.com",
  adminMail : "inveep.noreplay@gmail.com",
  // adminMail : "dalikrimi33@gmail.com"
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
